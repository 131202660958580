@import '../../../pages/App/sass/variables.scss';

.textField {
  width: 100%;
  align-items: center;
  color: $defaultText;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 120%;
  font-family: $main-font-family;

  @media only screen and (max-width: $screen-xxs) {
    font-size: 14px;
  }
}

.inputLabel {
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin: 24px 0 16px;
  max-width: max-content;

  @media #{$full-hd} {
    font-size: 15px;
  }

  @media only screen and (max-width: $screen-xxs) {
    margin: 22px 0 12px;
    white-space: normal;
  }
}

.inputLabelDesc {
  width: auto;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin: 24px 0 16px 5px;
  color: $defaultIconColor;

  @media only screen and (max-width: $screen-xxs) {
    margin: 22px 0 12px 5px;
  }
}

.titleFormBig {
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  margin: 40px 0 24px;
}

.titleForm {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 11px;
}

.description {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: $descriptionText;
  margin-bottom: 24px;
}

.timerLabel {
  width: fit-content;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $timerText;
  white-space: nowrap;
  margin-right: 10px;

  @media only screen and (max-width: $screen-min-s) {
    white-space: normal;
  }
}

.descriptionPass {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: $descriptionText;
}

.timer {
  width: fit-content;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $timerText;
  white-space: nowrap;
}

.labelBanner {
  font-weight: 700;
  font-size: 70px;
  line-height: 170%;
  margin-bottom: 36px;

  @media only screen and (max-width: $screen-xxs) {
    font-size: 32px;
    line-height: 51px;
    margin-bottom: 24px;
  }
}

.bannerDescription {
  font-weight: 500;
  font-size: 18px;
  line-height: 170%;
  color: $bannerDescription;

  @media only screen and (max-width: $screen-xxs) {
    font-size: 14px;
    line-height: 28px;
  }
}

.styleDescriptionSmall {
  @extend .descriptionPass;
  margin: 25px 0;

  @media only screen and (max-width: $screen-xxs) {
    margin: 16px 0 11px;
  }
}

.copyLinkButton {
  width: fit-content;
  margin-left: 15px;
  font-weight: 600;
  white-space: nowrap;

  @media only screen and (max-width: $screen-xxs) {
    margin-left: 3px;
  }
}

.addTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;

  @media only screen and (max-width: $screen-xxs) {
    font-size: 24px;
    line-height: 38px;
  }
}

.titleCards {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  margin: 20px 0;
  text-align: center;

  @media only screen and (max-width: $screen-xxs) {
    font-size: 20px;
    line-height: 25px;
  }
}


.descriptionCards {
  @extend .descriptionPass;
  max-width: 350px;
  font-size: 18px;
  font-weight: 500;
  line-height: 170%;
  margin-bottom: 24px;
  text-align: center;

  @media only screen and (max-width: $screen-xxs) {
    font-size: 14px;
    line-height: 170%;
    margin-bottom: 0;
  }
}

.bannerDescriptionCounter {
  @extend .descriptionPass;
  font-size: 18px;
  font-weight: 500;
  line-height: 170%;
  margin-bottom: 20px;
  margin-top: 20px;

  @media only screen and (max-width: $screen-xs) {
    font-size: 14px;
  }
}

.titleInfoCard {
  font-size: 24px;
  line-height: 30px;

  @media only screen and (max-width: $screen-xs) {
    font-size: 20px;
    margin-top: 12px;
  }
}

.goalTextDesc {
  width: fit-content;
  font-size: 18px;
  line-height: 170%;
  color: $bannerDescription;

  @media only screen and (max-width: $screen-xs) {
    font-size: 16px;
    margin-top: 12px;
  }
}

.goalText {
  font-weight: 500;
  width: fit-content;
  font-size: 18px;
  line-height: 170%;
  color: $bannerDescription;

  @media only screen and (max-width: $screen-xs) {
    font-size: 16px;
    margin-top: 18px;
  }
}

.termText {
  font-weight: 400;
  font-size: 18px;
  line-height: 43px;
  color: $darkGrey;

  @media only screen and (max-width: $screen-xs) {
    font-size: 14px;
    line-height: 33px;
  }
}

.termsTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  margin-bottom: 20px;

  @media only screen and (max-width: $screen-xs) {
    font-size: 24px;
    line-height: 30px;
  }
}

.name {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  display: block;
  margin-bottom: 5px;
  margin-top: 8px;
}

.type {
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: $seekBarColor;
  display: block;
  text-align: center;
  text-transform: uppercase;
}

.sidebarItem_title {
  width: auto;
  font-weight: 450;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0;
}

.sidebarSubItem_title {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0;
  width: auto;
}

.sidebarSubItem_description {
  margin-top: 6px;
  flex-basis: 100%;
  font-weight: 450;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0;
  width: auto;
}

.dashboardTitle {
  font-weight: 500;
  display: block;
  font-size: 24px;
  line-height: 120%;
}

.dashboardDescription {
  font-weight: 450;
  font-size: 18px;
  line-height: 120%;
  color: $darkGrey;
  display: block;
  @media #{$phones} {
    font-size: 14px;
    line-height: 24px;
  }
}

.courseTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  display: block;
  margin-bottom: 24px;
  @media #{$phones} {
    font-size: 20px;
  }
}

.courseDescription {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 170%;
  color: $buttonColor;
  display: block;
  margin-bottom: 6px;
  @media #{$phones} {
    font-size: 16px;
  }
}

.courseTutor {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: $seekBarColor;
  display: block;

  @media #{$phones} {
    font-size: 14px;
  }
}

.courseSidebarMain {
  width: auto;
  font-size: 22px;
  line-height: 120%;
  font-weight: 500;
  color: $seekBarColor;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  -ms-line-clamp: 1;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
}

.courseSidebarDescription {
  display: block;
  width: auto;
  max-width: 185px;
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
  color: $defaultWhite;
}

.courseSidebarButton {
  width: auto;
  font-size: 16px;
  font-weight: 450;
  line-height: 120%;
  color: $defaultWhite;
}

.typeOfCourseDefault {
  position: absolute;
  top: 0;
  right: 33px;
  width: max-content;
  padding: 7px 20px;
  border-radius: 0 0 16px 16px;
  background-color: $seekBarColor;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: $defaultWhite;
  text-transform: capitalize;
}

.class, .mentor, .participant, .tutor {
  position: absolute;
  top: 0;
  right: 0;
  width: max-content;
  padding: 6px 10px;
  font-size: 10px;
  border-radius: 16px;
  color: $defaultWhite;

  @media #{$desktop} {
    padding: 4px 10px;
  }
}

.class {
  background-color: $purpleChat;
}

.tutor {
  background-color: $seekBarColor;
}

.participant {
  background-color: $blueChat;
}

.mentor {
  background-color: $timerText;
}

.typeOfCourseOnline {
  position: absolute;
  top: 0;
  right: 33px;
  width: max-content;
  padding: 7px 20px;
  border-radius: 0 0 16px 16px;
  background-color: $defaultBlack;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: $defaultWhite;
  text-transform: capitalize;
}

.courseTimeSmall, .courseTimeLarge {
  width: auto;
  font-weight: 500;
  line-height: 150%;

}

.courseTimeSmall {
  font-size: 14px;
}

.courseTimeLarge {
  font-size: 16px;

  @media #{$phones} {
    font-size: 14px;
  }

  &:last-child {
    color: $descriptionText;
  }
}

.semikolon {
  width: auto;
  padding-left: 3px;
  padding-right: 3px;
}

.progressStep {
  color: $lightGrey;
  font-weight: 450;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
}

.progressStepDone {
  font-weight: 450;
  font-size: 14px;
  line-height: 120%;
  color: $defaultText;
  text-align: center;
  width: 120%;
}

.profileTitle {
  font-weight: 500;
  font-size: 28px;
  line-height: 140%;
  @media #{$tablets} {
    font-size: 24px;
  }
}

.descTitle {
  font-weight: 450;
  font-size: 18px;
  line-height: 120%;
  margin-top: 16px;
  color: $darkGrey;
  @media #{$tablets} {
    font-size: 14px;
    width: 100%;
  }
}

.deadlineText {
  @extend .descTitle;
  @media #{$tablets} {
    color: $buttonColor;
  }
}

.participantBlockDesc {
  @extend .descTitle;
  font-size: 14px;
  @media #{$tablets} {
    display: none;
  }
}

.participantBlockTitle {
  @extend .profileTitle;
  font-size: 18px;

  @media #{$tablets-big} {
    margin-top: 16px;
    margin-bottom: 4px;
    text-align: center;
  }
  @media #{$tablets} {
    font-size: 16px;
  }
  @media #{$tablets} {
    font-size: 16px;
  }
}

.idNameText {
  @extend .participantBlockTitle;
  @media #{$tablets} {
    font-weight: 700;
    font-size: 24px;
    line-height: 158%;
    color: $seekBarColor;
  }
}

.styleDescAvatar {
  @extend .descTitle;
  margin-top: 6px;
  font-size: 14px;

  @media #{$tablets-big} {
    text-align: center;
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}

.seekBarLabel {
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 16px;
}

.titleStepItem {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: $dirtyGray;
  white-space: nowrap;

  @media only screen and (max-width: $screen-max) {
    font-size: 14px;
  }
}

.activeStepItem {
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: $defaultBlack;
  white-space: nowrap;

  @media only screen and (max-width: $screen-max) {
    font-size: 14px;
  }
}

.stepDesc {
  font-weight: 450;
  font-size: 16px;
  line-height: 120%;
  color: $darkGrey;
  @media #{$tablets} {
    font-size: 14px;
  }
}

.formErrorMessage {
  color: $defaultRed;
  font-size: 16px;
  width: max-content;
}

.titleProfileSmall {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 16px;
}

.inputDescription {
  color: $darkGrey;
  margin-bottom: 16px;
}

.checkboxLabel {
  font-size: 14px;
  line-height: 18px;
}

.profileTitleSecond {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 16px;
}

.stepDescSecond {
  font-size: 16px;
  font-weight: 450;
  line-height: 19px;
  color: $darkGrey;
}

.miniTitle {
  margin-top: 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}

.titleSuccess {
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
}

.descriptionSuccess {
  width: 75%;
  font-weight: 450;
  font-size: 18px;
  line-height: 200%;
  text-align: center;
  margin: 12px 0 32px;
  color: $descriptionText;
}

.labelTextField {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 16px;
  @media #{$tablets-big} {
    margin-bottom: 5px;
    font-size: 14px;
  }
}

.valueLabelTextField {
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 60px;

  @media #{$desktop} {
    margin-bottom: 30px;
  }
  @media #{$tablets-big} {
    font-size: 14px;
    overflow-wrap: break-word;
  }
}

.viewProfileInfoTitle {
  font-weight: 500;
  font-size: 28px;
  line-height: 140%;
  margin-bottom: 24px;
  @media #{$tablets-big} {
    font-size: 24px;
  }
}

.displayNone {
  display: none;
}

.documentsTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  margin-bottom: 16px;
  display: block;
}

.documentsDescription {
  font-weight: 450;
  font-size: 18px;
  line-height: 128%;
  color: $darkGrey;
  display: block;
}

.afterCourseDescription {
  display: block;
  font-size: 24px;
  line-height: 36px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 500;
  color: $buttonColor;

  @media only screen and (max-width: $screen-md) {
    font-size: 20px;
    line-height: 24px;
  }

  @media only screen and (max-width: $screen-xs) {
    font-size: 14px;
    line-height: 24px;
  }
}

.afterCourseDate {
  width: auto;
  margin-right: 3px;
  font-size: 16px;
  line-height: 25px;
  @media only screen and (max-width: $screen-xs) {
    font-size: 14px;
    line-height: 22px;
  }
}

.afterCourseDateValue {
  width: auto;
  margin-left: 3px;
  color: $descriptionText;
  font-size: 16px;
  line-height: 25px;
  @media only screen and (max-width: $screen-xs) {
    font-size: 14px;
    line-height: 22px;
  }
}

.afterCourseLinksTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  display: block;
}

.afterCourseLinksDescription {
  font-weight: 450;
  font-size: 18px;
  line-height: 32px;
  color: $darkGrey;
  margin-top: 12px;
  display: block;
  max-width: 790px;
  @media only screen and (max-width: $screen-xs) {
    font-size: 14px;
    line-height: 24px;
  }
}

.quickLinkTitle {
  text-align: center;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  color: $descriptionText;
  margin-bottom: 15px;
  max-width: 72%;
}

.quickLinkTitleMentor {
  @extend .quickLinkTitle;
  color: $defaultText;
  margin-bottom: 0;
}

.quickLinkDescription {
  font-weight: 450;
  font-size: 16px;
  line-height: 1.2em;
  color: $dirtyGray;
  text-align: center;
  margin-bottom: 12px;
}

.feedbackUserName {
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: $defaultBlack;
  @media only screen and (max-width: $screen-xs) {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }
}

.feedbackDescription {
  font-size: 24px;
  line-height: 36px;
  color: $buttonColor;
  max-width: 860px;
  display: block;
  @media only screen and (max-width: $screen-xs) {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
}

.feedbackTitle {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  @media only screen and (max-width: $screen-xs) {
    font-size: 18px;
    line-height: 23px;
  }
}

.welcomeTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  margin-bottom: 8px;

  @media #{$phones} {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 8px;
  }
}

.dashboardTitle {
  font-weight: 500;
  display: block;
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 16px;
  color: $buttonColor;

  @media #{$phones} {
    font-size: 16px;
    line-height: 24px;
  }
}

.dashboardManager {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  @media #{$phones} {
    font-size: 14px;
  }
}

.dashboardManagerTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;

  @media #{$phones} {
    font-size: 16px;
  }
}

.courseUpdatesDate {
  min-width: 55px;
  max-width: 55px;
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: $dirtyGray;

  @media #{$phones} {
    min-width: 45px;
    max-width: 45px;
    font-size: 14px;
  }
}

.courseUpdatesTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;

  @media #{$phones} {
    font-size: 16px;
    line-height: 20px;
  }
}

.courseUpdatesDesc {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: $buttonColor;
}

.scheduleDate {
  width: auto;
  font-size: 18px;
  font-weight: 500;
  color: $dirtyGray;
  grid-area: date;

  @media #{$tablets-big} {
    font-size: 16px;
  }
}

.scheduleTitle {
  width: auto;
  font-size: 32px;
  font-weight: 500;
  grid-area: title;

  @media #{$tablets-big} {
    font-size: 20px;
  }
}

.scheduleLocation {
  font-size: 16px;
  font-weight: 500;
  color: $descriptionText;

  @media #{$tablets-big} {
    font-size: 14px;
  }
}

.scheduleLocationName {
  font-size: 22px;
  font-weight: 450;
  line-height: 150%;
  color: $defaultBlue;

  @media #{$tablets-big} {
    font-size: 18px;
  }
}

.tasksTitle {
  width: auto;
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tasksDate {
  width: auto;
  font-size: 20px;
  line-height: 150%;
  font-weight: 500;
  justify-self: center;
  color: $descriptionText;
}

.eventsTitle {
  width: auto;
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
}

.eventsLocationName {
  font-size: 18px;
  font-weight: 500;
  color: $defaultBlue;
  line-height: 150%;
}

.notificationTitle {
  color: $defaultWhite;
  font-size: 14px;
  font-weight: 450;
  line-height: 140%;
}

.messagingTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;

  @media #{$phones} {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }
}

.messagingDescription {
  font-weight: 450;
  font-size: 18px;
  color: $dirtyGray;

  @media #{$phones} {
    font-size: 14px;
    line-height: 24px;
  }
}

.chatChannelTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.chatChannelLastMessage {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: $descriptionText;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 230px;

  @media #{$desktop} {
    width: 200px;
  }
}

.modalFilesTitle {
  line-height: 24px;
}

.chatHeaderTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;

  @media #{$tablets} {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
  }
}

.groupMessagesTitle {
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;

  @media #{$tablets} {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
  }
}

.chatHeaderNickname {
  font-weight: 500;
  font-size: 14px;
  max-width: 64px;
}

.chatHeaderConnection {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: $statusGrey;
  font-family: Open-Sans, sans-serif;
}

.chatMessageItem, .chatMessageItemOwn {
  width: fit-content;
  max-width: 362px;
  word-wrap: break-word;
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
  padding: 10px;
  font-family: Open-Sans, sans-serif;
  margin-bottom: 8px;

  @media #{$phones} {
    max-width: 320px;
  }

  @media #{$phones-xs} {
    max-width: 280px;
  }
}

.chatMessageItem {
  background: $milkyColor;
  border-radius: 24px 24px 24px 0;
  color: $defaultBlack;
}

.chatMessageItemOwn {
  background: $descriptionText;
  border-radius: 24px 24px 0 24px;
  color: $defaultWhite;
  justify-self: flex-end;
}

.chatMessageItemDate, .chatMessageItemInitialsSmall, .chatMessageItemInitialsLarge {
  width: auto;
  font-size: 12px;
  line-height: 23px;
  font-family: Open-Sans, sans-serif;
}

.chatMessageItemInitialsSmall, .chatMessageItemInitialsLarge {
  color: $defaultWhite;
}

.chatMessageItemInitialsLarge {
  font-size: 16px;
}

.sendButtonText {
  width: auto;
  font-size: 14px;
  font-weight: 600;
  color: $defaultWhite;

  @media #{$phones} {
    display: none;
  }
}

.calendarPopUpTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25em;
  color: $defaultText;
  width: max-content;
}

.calendarPopUpComment {
  width: 100%;
  font-weight: 450;
  font-size: 16px;
  line-height: 2.06em;
  color: $darkGrey;
}
.courseInfoTitle {
  font-size: 40px;
  font-weight: 700;
  line-height: 140%;

  @media #{$tablets} {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
  }
}

.courseInfoCardDescription {
  font-size: 16px;
  font-weight: 500;
  color: $dirtyGray;
  line-height: 24px;

  @media #{$tablets} {
    font-size: 14px;
    line-height: 21px;
  }
}

.courseInfoCardTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;

  @media #{$tablets} {
    font-size: 19px;
    line-height: 23px;
  }
}

.courseInfoCardLocation {
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: $seekBarColor;

  @media #{$tablets} {
    font-size: 14px;
    line-height: 21px;
  }
}

.courseInfoCardStreet {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin-top: 12px;

  @media #{$tablets} {
    font-size: 14px;
    line-height: 21px;
    margin: 0;
  }
}

.courseInfoCardDescTitle {
  font-size: 16px;
  font-weight: 450;
  line-height: 23px;
}

.courseInfoProgramTitle {
  width: auto;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;

  @media #{$desktop} {
    font-size: 32px;
    line-height: 32px;
  }

  @media #{$phones} {
    font-size: 20px;
    line-height: 25px;
  }
}

.courseInfoDescProgram {
  width: auto;
  font-size: 18px;
  font-weight: 450;
  line-height: 57px;
  color: rgba($buttonColor, 0.5);

  @media #{$tablets-big} {
    line-height: 34px;
  }

  @media #{$phones} {
    font-size: 14px;
    line-height: 23px;
  }
}

.courseInfoDescProgramTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;

  @media #{$phones} {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
  }
}

.markModalTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;

  @media #{$tablets-big} {
    font-size: 20px;
    line-height: 24px;
  }

  @media #{$phones} {
    font-size: 18px;
  }
}

.markModalPosted {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: $seekBarColor;

  @media #{$tablets-big} {
    font-size: 14px;
    line-height: 18px;
  }
}

.markModalDesc {
  max-width: 615px;
  font-size: 18px;
  font-weight: 450;
  line-height: 41px;
  color: $buttonColor;
  margin-top: 4px;

  @media #{$tablets-big} {
    font-size: 16px;
    line-height: 34px;
  }

  @media #{$phones} {
    font-size: 14px;
    line-height: 24px;
  }
}

.tableLayoutTutor {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;

  @media #{$phones} {
    font-size: 18px;
    line-height: 24px;
  }
}

.tableLayoutDescription {
  font-size: 18px;
  font-weight: 500;
  color: $buttonColor;

  @media #{$phones} {
    font-size: 16px;
  }
}

.yourCollegeCardLabel {
  font-size: 16px;
  line-height: 1.2em;
  color: $dirtyGray;
  margin-bottom: 12px;
  width: max-content;
  @media #{$phones} {
    font-size: 12px;
  }
}

.yourCollegeCardTitle {
  font-size: 24px;
  line-height: 1.2em;
  width: fit-content;
  padding: 0 14px;

  @media #{$phones} {
    font-size: 18px;
  }
}

.mentorInfoTextTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5em;
  margin-bottom: 16px;
  display: block;
  @media #{$phones} {
    font-size: 18px;
  }
}
.attendanceStatusCount {
  width: auto;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  color: $descriptionText;
}

.evaluationModalUser {
  line-height: 34px;
  font-size: 24px;
  font-weight: 500;

  @media #{$tablets} {
    font-size: 20px;
  }

  @media #{$tablets} {
    font-size: 18px;
    line-height: 20px;
  }
}

.evaluationModalDescribe {
  font-size: 18px;
  line-height: 32px;
  font-weight: 450;
  color: $buttonColor;

  @media #{$tablets} {
    font-size: 16px;
  }

  @media #{$phones-xs} {
    font-size: 14px;
    line-height: 24px;
  }
}

.userProfileInfoTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.userProfileInfoDesc {
  word-wrap: break-word;
  max-width: 810px;
  color: $buttonColor;
  font-size: 16px;
  font-weight: 450;
  line-height: 33px;
}

.userProfileBlockTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
}

.userProfileEvaluation {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  padding-bottom: 8px;
}

.inputLabelIncident {
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 16px;
  max-width: max-content;

  @media #{$full-hd} {
    font-size: 15px;
  }

  @media only screen and (max-width: $screen-xxs) {
    margin-bottom: 12px;
    white-space: normal;
  }
}

.individualsInfoTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.incidentReportTitle {
  font-size: 28px;
  text-align: left;
  font-weight: 500;
  line-height: 39px;

  @media #{$tablets} {
    font-size: 22px;
  }
}

.incidentReportDetailsTitle {
  @extend .incidentReportTitle;
  display: block;
  margin-bottom: 20px;
}

.tableActivity {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.additionalParticipantTableTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  width: max-content;

  @media #{$tablets-big} {
    font-size: 26px;
  }

  @media #{$phones} {
    font-size: 20px;
    line-height: 25px;
  }
}

.incidentDetailsStatusResolved {
  display: block;
  width: max-content;
  color: $timerText;
}

.incidentDetailsStatusPending {
  color: $defaultRed;
}

.resolvedIncident {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  padding: 12px 20px;
  color: $timerText;
  background: $lightGreen;
  border-radius: 6px;
}

.notResolvedIncident {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  padding: 12px 32px;
  color: $defaultRed;
  background: rgba($defaultRed, 0.16);
  border-radius: 6px;
}

.supportText {
  color: $defaultBlack;
  font-size: 16px;

  @media #{$phones} {
    font-size: 14px;
  }
}

.supportTextEmail {
  color: $statusGrey;
  font-size: 14px;

  @media #{$phones} {
    font-size: 12px;
  }
}

.participants {
  font-weight: 450;
  font-size: 14px;
  line-height: 120%;
  color: $defaultText;
  width: 120%;
}
