@import '../../../pages/App/sass/variables.scss';

.button {
  width: 100%;
  padding: 25px 0;
  border: none;
  background: $buttonColor;
  border-radius: 35px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $defaultWhite;
  transition: background-color 0.6s ease;
  font-family: $main-font-family;
  &:disabled,
  &[disabled]{
    background: $defaultGray;
    pointer-events: none;
    &:hover {
      background: $defaultGray;
    }
  }
  &:hover{
    background: $defaultHoverColor;
    opacity: .8;
  }
  &:active {
    background: $defaultActiveColor;
  }

  @media only screen and (max-width: $screen-xxs) {
    padding: 20px 0;
  }

  @media only screen and (max-width: $screen-min-s) {
    padding: 14px 0;
  }

}

.receiveEmail {
  width: fit-content;
  height: auto;
  background: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: $defaultBlue;
  &:hover{
    background: transparent;
    opacity: .8;
  }

}

.headerButton {
  background-color: $whiteGrey;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: $screen-xs) {
    display: none;
  }
}

.burgerButton {
  display: none;
  &:hover {
    background-color: transparent;
  }
  @media only screen and (max-width: $screen-xs) {
    display: block;
    padding: 0;
    background-color: transparent;
    width: max-content;
  }
}

.inputPasswordBtn {
  width: 30px;
  height: 23px;
  position: absolute;
  background: transparent;
  top: 16%;
  right: 20px;

  &:hover{
    background: transparent;
  }
}

.referButton {
  width: 126px;
  height: 44px;
  background: $seekBarColor;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $defaultWhite;

  &:hover{
    background: $seekBarColor;
    opacity: .8;
  }

  @media only screen and (max-width: $screen-xxs) {
    width: 100%;
    height: 48px;
    margin-top: 12px;
  }
}

.copyLinkButton {
  max-width: 190px;
  max-height: 56px;
  display: flex;
  align-items:center;
  padding: 16px 38px;
  background: $defaultWhite;
  border-radius: 6px;
  margin-right: 7px;

  &:hover{
    background: $defaultWhite;
  }

  @media only screen and (max-width: $screen-sm) {
    max-width: 120px;
    max-height: 48px;
    padding: 16px 15px;
    margin-right: 4px;
  }

  @media only screen and (max-width: $screen-xxs) {
    margin: 0;
  }
}

.shareStyle {
  width: 56px;
  max-height: 56px;
  border-radius: 6px;
  background: $defaultWhite;
  margin: 7px;
  &:hover{
    background: $defaultWhite;
  }

  @media only screen and (max-width: $screen-sm) {
    width: 48px;
    max-height: 48px;
    margin: 4px;
  }

  @media only screen and (max-width: $screen-xxs) {
    margin: 0;
  }

}

.inviteFriend {
  width: 156px;
  height: 48px;
  font-size: 16px;

  @media only screen and (max-width: $screen-sm) {
    width: 100%;
    font-size: 14px;
  }
}

.editAvatar {
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 50%;
  position: absolute;
  bottom: 6px;
  right: 0;
}

.saveAndContinue {
  width: 185px;
  height: 48px;
}

.saveAndContinueGhost, .ghostButton {
  width: max-content;
  padding: 14px 32px;
  border: 1px solid $buttonColor;
  color: $buttonColor;
  background-color: transparent;
  transition: all 0.3s ease;
  &:hover {
    background-color: $buttonColor;
    color: $defaultWhite;
    box-shadow: 0px 11px 22px rgba(0, 0, 0, 0.1);
  }
}

.ghostButton {
  padding: 14px 32px;
}

.iconButton {
  background-color: transparent;
  width: max-content;
  padding: 0;

  &:hover {
    background-color: transparent;
  }
}

.downloadPDFParticipant {
  width: auto;
  padding: 14px 32px;
}
.iconButtonLink {
  padding: 20px 0;
  height: 43px;
  display: flex;
  gap: 12px;
  background-color: $whiteGrey;
  color: $descriptionText;
  font-size: 12px;
  font-weight: 450;
}

.sidebarButton {
  width: 210px;
  padding: 13px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: rgba($defaultWhite, 0.19);;
  font-weight: 450;
}

.taskStatusCompleted, .taskStatusSubmitted {
  width: auto;
  cursor: initial;
  background-color: $lightPink;
  color: $purple;
  padding: 12px 30px;
  border-radius: 6px;

  &:hover {
    background-color: $lightPink;
  }
}

.taskStatusInProgress, .taskStatusNotStarted, .taskStatusNotAvailableYet {
  width: auto;
  cursor: initial;
  background-color: $taskBlue;
  color: $descriptionText;
  padding: 12px 30px;
  border-radius: 6px;

  &:hover {
    background-color: $taskBlue;
  }
}

.signedUpEvent {
  cursor: initial;
  min-width: 185px;
  width: auto;
  padding: 12px 30px;
  font-size: 16px;
  background-color: rgba($timerText, 0.2);
  border-radius: 6px;
  color: $timerText;

  &:hover {
    background-color: rgba($timerText, 0.1);
  }
}

.signUpEvent {
  min-width: 158px;
  width: auto;
  padding: 12px 30px;
  border: 1px solid $buttonColor;
  border-radius: 40px;
  background-color: $defaultWhite;
  color: $buttonColor;

  &:hover {
    color: $defaultWhite;
  }
}

.cancelEvent {
  min-width: 112px;
  width: auto;
  padding: 12px 30px;
  background: $defaultGray;
  color: $defaultWhite;
  border-radius: 40px;
}

.messagingButton {
  width: 134px;
  height: 48px;
  margin-top: 32px;
  margin-bottom: 24px;

  @media #{$tablets} {
    margin: 32px 0;
  }
}

.chatMessageButton {
  width: 105px;
  height: 48px;
  background: $defaultBlue;
  border-radius: 5px;
  display: flex;
  gap: 12px;

  @media #{$phones} {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    padding: 0;
  }
}

.chatMessageEmojiIcon {
  background-color: transparent;
  width: max-content;
  padding: 0;

  &:hover {
    background-color: transparent;
  }
}

.filesListClose {
  position: absolute;
  right: 30px;
  top: 28px;
  cursor: pointer;
  background-color: transparent;
  width: max-content;
  padding: 0;

  @media #{$phones} {
    right: 16px;
    top: 18px;
  }

  &:hover {
    background-color: transparent;
  }
}

.courseInfoDescription {
  display: flex;
  gap: 8px;
  padding: 0;
  width: fit-content;
  background-color: transparent;
  align-self: flex-end;

  &:hover {
    background-color: transparent;
  }
}

.courseInfoProgramMark {
  width: fit-content;
  height: 48px;
  padding: 14px 32px;
  margin-right: 49px;

  @media #{$desktop} {
    margin: 0;
  }

  @media #{$tablets-big} {
    display: none;
  }
}

.courseInfoProgramMarkGold {
  display: none;
  width: auto;
  padding: 0;
  background-color: transparent;
  color: $seekBarColor;
  justify-content: flex-end;
  margin-bottom: 29px;

  @media #{$tablets-big} {
    display: flex;
  }

  &:hover {
    background-color: transparent;
  }
}

.studentsMessageButton {
  width: max-content;
  padding: 13px 32px;
  background-color: transparent;
  color: $buttonColor;
  border: 1px solid $buttonColor;
  border-radius: 40px;

  &:hover {
    color: $defaultWhite;
  }
}

.viewProfileButton {
  width: max-content;
  padding: 14px 32px;
  background: $buttonColor;
}

.submittedButton {
  width: auto;
  padding: 12px 20px;
  color: $timerText;
  background: rgba($timerText, 0.16);
  border-radius: 6px;

  &:hover {
    background: rgba($timerText, 0.1);
  }
}

.submitButton {
  width: max-content;
  padding: 12px 32px;
  color: $defaultRed;
  background: rgba($defaultRed, 0.16);
  border-radius: 6px;

  &:hover {
    background: rgba($defaultRed, 0.1);
  }
}

.pageItem, .pageItemActive {
  width: 37px;
  height: 37px;
  padding: 0;
  border-radius: 24px;
  background-color: transparent;
  color: $defaultText;
  font-size: 14px;
  font-weight: 450;
  line-height: 21px;

  &:hover {
    color: $defaultText;
    background-color: rgba($buttonColor, 0.1);
  }
}

.pageItemActive {
  background: $buttonColor;
  color: $defaultWhite;
}

.pageItemArrowRight, .pageItemArrowLeft {
  width: 37px;
  height: 37px;
  padding: 0;
  background-color: $defaultWhite;
  box-shadow: 0 2px 4px rgba($defaultText, 0.05);
  border-radius: 22px;

  &:hover {
    background-color: rgba($buttonColor, 0.1);
  }
}

.pageItemArrowRight {
  transform: rotate(180deg);
}

.attendanceStatusTake {
  width: 174px;
  padding: 12px 25px;
  background-color: $buttonColor;
  border-radius: 28px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.resolvedButton {
  @extend .submittedButton;
  padding: 12px 25px;
  line-height: 24px;
}

.submitEvaluation {
  padding: 14px 32px;
  width: max-content;
}

.evaluationSaveModal {
  width: auto;
  padding: 14px 56px;
  height: 48px;
  background-color: transparent;
  color: $buttonColor;
  border: 1px solid $buttonColor;
  border-radius: 40px;

  &:hover {
    color: $defaultWhite;
  }
}

.evaluationSubmitModal {
  width: auto;
  height: 48px;
  padding: 14px 47px;
}

.returnToLogin {
  margin-top: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $defaultBlue;
  background-color: transparent;
  height: fit-content;
  padding: 0;

  &:hover {
    color: $defaultBlue;
    background-color:  transparent;
  }
}
